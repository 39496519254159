import Link from 'next/link';
import { SiDiscord, SiTwitter } from 'react-icons/si';
import { SlGlobe } from 'react-icons/sl';

export default function SocialIcons({ discord, twitter, homepage, style }) {
  return (
    <div className="flex items-center h-full space-x-[12px]" style={style}>
      {homepage && (
        <div className="tooltip" data-tip="Visit Homepage">
          <Link
            href={homepage}
            className="cursor-pointer hover:text-accent"
            target="_blank"
            rel="noreferrer"
          >
            <SlGlobe size="1.2em" />
          </Link>
        </div>
      )}
      {discord && (
        <div className="tooltip" data-tip="Join Discord">
          <Link
            href={discord}
            className="cursor-pointer hover:text-accent"
            target="_blank"
            rel="noreferrer"
          >
            <SiDiscord size="1.2em" />
          </Link>
        </div>
      )}
      {twitter && (
        <div className="tooltip" data-tip="Visit Twitter">
          <Link
            href={twitter}
            className="cursor-pointer hover:text-accent"
            target="_blank"
            rel="noreferrer"
          >
            <SiTwitter size="1.2em" />
          </Link>
        </div>
      )}
    </div>
  );
}
