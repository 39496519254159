import { LAMPORTS_PER_SOL } from '@solana/web3.js';

export const BIDHUB_TWITTER = 'https://twitter.com/Block9_NFT';
export const BIDHUB_DISCORD = 'https://discord.gg/block9';

// The ABSOLUTE_MIN_BID must be large enough to exist rent-free due to
// the possibility of an account closing between bidding and refunding
export const ABSOLUTE_MIN_BID = parseInt(LAMPORTS_PER_SOL / 1000, 10); // 0.001 SOL
export const LAUNCHPAD_FEE = parseInt(LAMPORTS_PER_SOL / 100, 10); // 0.01 SOL
export const EDITION_MINT_FEE = parseInt(0.024 * LAMPORTS_PER_SOL); // 0.024 SOL

export const COMPUTE_BUDGET_MICROLAMPORTS = process.env.COMPUTE_BUDGET_MICROLAMPORTS || 2500;

/**
 * We do not allow more than 8 bids due to limits on transaction
 * sizes in Solana. We need database updates to be atomic, and that
 * means all bids either pass or fail based on a single Solana tx.
 *
 * See the scripts/calculateMaxTxes.mjs file for details.
 * */
export const MAX_ALLOWED_BIDS = 8;

export const MEMO_PROGRAM_PKEY = 'MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr';

export const GLOBAL_REFRESH_INTERVAL = 20000; // 20 seconds in ms
export const MIN_REFRESH_INTERVAL = 5000; // Don't fetch data more frequently than every 5 seconds

export const BLANK_PNG =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';

export const CACHE_TIMES = {
  pot: 3, // 3 seconds
  default: 60 * 5, // 5 minutes
};
