import Image from 'next/image';
import Link from 'next/link';

export default function LaunchpadLogo({ href = '/', children, height = 32, width = 143 }) {
  return (
    <Link href={href} className="flex items-center space-x-2">
      <Image src="/logo-darkmode-2x.png" height={height} width={width} alt="BidHub logo" />
      {children}
    </Link>
  );
}
