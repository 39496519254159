'use client';

import Link from 'next/link';
import { BIDHUB_DISCORD, BIDHUB_TWITTER } from 'lib/constants';
import BidhubLogo from 'components/LaunchpadLogo';
import SocialIcons from 'components/SocialIcons';

export default function BidhubFooter() {
  return (
    <div className="flex flex-col w-full bg-base-200 items-center">
      <div className="h-10" />
      <div className="flex flex-col md:flex-row md:justify-around max-w-screen-lg w-full p-4">
        {/* About Blurb */}
        <div className="flex flex-col max-w-sm space-y-4 text-neutral-400">
          <div className="flex items-center w-full space-x-6 h-12">
            <BidhubLogo height={27} />
            <SocialIcons twitter={BIDHUB_TWITTER} discord={BIDHUB_DISCORD} />
          </div>
          <p>
            BidHub is the premier fair-market mint experience for collectors, teams, and artists on
            Solana. Empowering holders to pay what they want while maximizing profits for creators.
          </p>
          <p>Brought to you by BLOCK9.</p>
        </div>

        {/* Explore */}
        <div className="flex flex-col space-y-4">
          <h3 className="text-2xl font-bold">Explore</h3>
          <div className="flex flex-col space-y-2 text-neutral-400 hover:text-neutral-300">
            <Link className="cursor-pointer hover:underline" href="/bids">
              Account History
            </Link>
            <Link
              className="cursor-pointer hover:underline"
              href="https://block9.gitbook.io/"
              target="_blank"
            >
              The GitBook
            </Link>
          </div>
        </div>

        {/* Partners */}
        <div className="flex flex-col space-y-4">
          <h3 className="text-2xl font-bold">Partners</h3>
          <div className="flex flex-col space-y-2 text-neutral-400">
            <Link className="cursor-pointer hover:underline hover:text-neutral-300" href="/studio">
              Artist Studio
            </Link>
            <Link
              href="https://docs.google.com/forms/d/e/1FAIpQLSdlcDES1jmsjOa2yEPUFCqfkaZ-jSlMGGdtzVoKOGZFMRIwiA/viewform"
              className="cursor-pointer hover:underline hover:text-neutral-300"
              target="_blank"
            >
              Apply for Platform
            </Link>
            <Link
              href={BIDHUB_DISCORD}
              target="_blank"
              className="cursor-pointer hover:underline hover:text-neutral-300"
            >
              Contact us
            </Link>
          </div>
        </div>
      </div>
      <div className="h-10" />
    </div>
  );
}
